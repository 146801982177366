.App {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-title {
  font-size: 1.5rem;
  color: #325f74;
}

.ul-content {
  width: auto;
  display: flex;
  flex-direction: column;
}

.ul-content > li {
  width: auto;
  list-style: none;
  margin-top: 6px;
}

.tag-content {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}

.tag-content > img {
  margin-right: 6px;
}

.footer {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  justify-content: flex-end;
  padding: 12px;
}

.footer > div {
  margin-bottom: 6px;
}